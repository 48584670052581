import { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import Script from "react-load-script";
import routes from "./routes";
import "./App.scss";
import { init } from "@mpk/react/hocs";
import qs from "query-string";
import customInit from "./libs/custom/customInit";
import AppMode from "./AppMode";
import { inject, observer } from "mobx-react";
import AllProviders from "~context/providers";

const App = ({
    match,
    temporaryStore,
    envStore,
    navigationStore,
    authStore,
}) => {
    const [checked, setChecked] = useState(false);

    // useEffect(async () => {
    //   if (AppMode.loginKeycloack == true) {
    //     setTimeout(async () => {
    //       if (sessionStorage.getItem(`session.e-meterai.pajakku`)) {

    //       } else {
    //         var loginRedirect = `${AppMode.loginUrl}/${AppMode.loginPath}?client_id=${AppMode.loginClientID}&response_type=code&scope=${AppMode.loginScope}&redirect_uri=${AppMode.loginRedirectUrl}`
    //         var logoutRedirect = `${AppMode.loginUrl}/auth/realms/emeterai/protocol/openid-connect/logout?redirect_uri=${AppMode.loginRedirectUrl}`
    //         // console.log("TELL ME ABOUT IT", window.location.href)
    //         var url = window.location.href
    //         if (url.indexOf('callback') != -1) {
    //           try {
    //             var getCode = url.split('code=')
    //             var code = getCode[1].split('#/')
    //             var realCode = code[0]
    //             // console.log(realCode)
    //             const params = new URLSearchParams();
    //             params.append('grant_type', 'authorization_code');
    //             params.append('client_id', `${AppMode.loginClientID}`);
    //             params.append('client_secret', 'a9ab9294-9874-4d82-8d64-8856cfca05c3');
    //             params.append('code', `${realCode}`);
    //             params.append('redirect_uri', `${AppMode.loginRedirectUrl}`);
    //             let res = await axios.post(`${AppMode.loginUrl}/auth/realms/emeterai/protocol/openid-connect/token`, params, {
    //               headers: {
    //                 "Content-Type": "application/x-www-form-urlencoded"
    //               }
    //             })
    //             // console.log(res.data)
    //             window.hahah = res.data
    //             localStorage.setItem("token", JSON.stringify(res.data))
    //             sessionStorage.setItem(`session.e-meterai.pajakku`, JSON.stringify(res.data))
    //             window.location.href = '/#/onpremise'
    //           } catch (e) {
    //             window.location.href = loginRedirect
    //           }
    //         } else {
    //           window.location.href = loginRedirect
    //         }
    //       }
    //     }, 250)
    //   }
    // })

    useEffect(() => {
        if (!checked && window.location.pathname === "/") {
            const parsedUrl = qs.parseUrl(window.location.href);
            navigationStore.redirectTo(
                `/${envStore.env.applicationType}${
                    Object.keys(parsedUrl.query).length > 0
                        ? `?${qs.stringify(parsedUrl.query)}`
                        : ""
                }`
            );
            setChecked(true);
        }
    }, [checked]);

    useEffect(() => {
        // window.onbeforeunload = function () {return false;}
    }, []);

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

    return (
        <div className="App mpk-full viewport-height">
            {Object.keys(envStore.env.widgetInterface).map((key) => (
                <Script
                    key={`widget-interface-${key}`}
                    url={envStore.env.widgetInterface[key].url}
                />
            ))}

            <AllProviders>
                <Router
                    history={navigationStore.browserHistory}
                    className="mpk-position relative"
                >
                    {routes()}
                </Router>
            </AllProviders>
        </div>
    );
};

var initOnPremise = AppMode.onPremise;
var initApp = init;
if (initOnPremise) {
    initApp = customInit;
}

// SSO or OnPremise
export default initApp()(
    inject("temporaryStore", "modalStore", "authStore")(observer(App))
);
