import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Suspense } from 'react';
import Loading from './modules/_modules/Component/Loading';

const Product = React.lazy(() => import('./pages/Product/Product.routes'))
const Internal = React.lazy(() => import('./pages/Internal.routes'))
const Onpremise = React.lazy(() => import('./pages/Onpremise.routes'))
const Error = React.lazy(() => import('./pages/Error'));

const routes = () => (
  <Switch>
    {/* PPH */}
    <Route path="/internal" render={(props) => (
      <Suspense fallback={<Loading />}>
        <Internal {...props} />
      </Suspense>
    )} />
    <Route path="/product" render={(props) => (
      <Suspense fallback={<Loading />}>
        <Product {...props} />
      </Suspense>
    )} />
    <Route path="/onpremise" render={(props) => (
      <Suspense fallback={<Loading />}>
        <Onpremise {...props} />
      </Suspense>
    )} />
    <Route path="/error" render={(props) => (
      <Suspense fallback={<Loading />}>
        <Error {...props} />
      </Suspense>
    )} />
  </Switch>
);

export default routes